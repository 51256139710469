<template>
  <div v-if="selectorItemDef && selectorItemDef.caption" class="selector-item-editor d-flex flex-column">
    <div class="flex-grow-1 scroll-area">
      <v-container style="max-width: 800px; margin: 50px auto 50px 50px;">
        <v-row>
          <v-col class="text-center text-h5">
            {{def.caption}}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2"></v-col>
          <v-col cols="8">
            <v-text-field 
              v-model="addItemText"
              outlined 
              background-color="white" 
              clearable 
              dense 
              hide-details
              placeholder="追加する場合はこちら"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-btn color="primary" class="white--text" depressed @click="addItem">追加</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="2"></v-col>
          <v-col cols="8">
            <v-subheader>登録中のアイテム</v-subheader>
            <v-list outlined>
              <template v-for="(item, i) in items">
                <v-list-item :key="i">
                  <v-list-item-icon class="mr-2">
                    <v-btn icon @click="moveUp(i)"><v-icon>mdi-arrow-up</v-icon></v-btn>
                    <v-btn icon @click="moveDown(i)"><v-icon>mdi-arrow-down</v-icon></v-btn>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-text-field 
                      v-if="def.itemType =='object'"
                      v-model="items[i][def.keyName]"
                      filled
                      rounded 
                      dense
                      hide-details
                    ></v-text-field>
                    <v-text-field 
                      v-else
                      v-model="items[i]"
                      filled
                      rounded 
                      dense
                      hide-details
                    ></v-text-field>

                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon @click="showDeleteConfirm(i)"><v-icon>mdi-delete</v-icon></v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-divider v-if="i < item.length-1" :key="'d' + i"></v-divider>
              </template>
            </v-list>
          </v-col>
          <v-col cols="2"></v-col>
        </v-row>
      </v-container>
    </div>

    <div class="flex-grow-0 action-area">
      <v-row style="max-width: 800px; margin: 0 auto 0 50px;">
        <v-col cols="2"></v-col>
        <v-col cols="8" class="text-center">
          <v-btn-accent label="保存" @click="saveConfirm = true"></v-btn-accent>
        </v-col>
      </v-row>
    </div>

    <yes-no-dialog
      message="保存しますか？"
      v-model="saveConfirm"
      yesText="保存する"
      noText="キャンセル"
      center
      @clickYes="save"
      @clickNo="saveConfirm = false"
    />
    <yes-no-dialog
      message="削除しますか？"
      v-model="deleteConfirm"
      yesText="削除する"
      noText="キャンセル"
      center
      @clickYes="deleteItem"
      @clickNo="deleteConfirm = false"
    />
  </div>
</template>
<script>
import VBtnAccent from '../../atoms/VBtnAccent.vue';
import YesNoDialog from '../../atoms/YesNoDialog.vue';

export default {
  components: { VBtnAccent, YesNoDialog },
  props: ['value', 'selectorItemDef'],
  data: () => ({
    saveConfirm: false,
    deleteConfirm: false,
    targetIndex: -1,

    addItemText: "",
    def: {
      caption: null,
      itemType: null,
      keyName: null,
    },
    items: [],

  }),

  mounted() {
    this.def = this.selectorItemDef;
    this.items = JSON.parse(JSON.stringify(this.value));
  },

  methods: {
    moveUp(i) {
      if(i == 0) return;
      
      const a = this.items[i-1];
      const b = this.items[i];
      this.items.splice(i-1, 1, b);
      this.items.splice(i, 1, a);
    },
    moveDown(i) {
      if(i == this.items.length-1) return;
      
      const a = this.items[i+1];
      const b = this.items[i];
      this.items.splice(i+1, 1, b);
      this.items.splice(i, 1, a);
    },
    addItem() {
      if(this.addItemText) {
        if(this.def.itemType == "object") {
          const maxId = this.items.reduce((a, b) => a > b ? a : b, 0);
          const item = { id: maxId+1 };

          item[this.def.keyName] = this.addItemText.trim();
          this.items.push(item);
        } else {
          this.items.push(this.addItemText.trim());
        }

        this.addItemText = "";
      }
    },

    deleteItem() {
      if(this.targetIndex > -1) {
        this.items.splice(this.targetIndex, 1);
      }
      this.deleteConfirm = false;
    },

    showDeleteConfirm(i) {
      this.targetIndex = i;
      this.deleteConfirm = true;
    },

    save() {
      console.log(this.items);
      this.$emit('input', this.items);
      this.saveConfirm = false;
    },
  },

}
</script>
<style lang="scss" scoped>
.selector-item-editor {
  height: 100%;;
  .scroll-area {
    @include scrollAreaY();
  }
  .action-area {
    background-color: white;
    border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
  }
}
</style>
