<template>
  <div class="d-flex fill-height"  >
    <selector-item-tree-view
      class="flex-grow-0"
      @select="selectTreeItem"
    ></selector-item-tree-view>
    <selector-item-editor
      class="flex-grow-1"

      :key="selectedKey"
      :selectorItemDef="selectorItemDef"
      :value="selectorItemValues"

      @input="save($event)"
    ></selector-item-editor>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import MasterData, { SelectorItemDefs } from '../../utils/MasterData';

import SelectorItemTreeView from '../../components/atomic/organisms/master/SelectorItemTreeView'
import SelectorItemEditor from '../../components/atomic/organisms/master/SelectorItemEditor'

export default {
  components: {
    SelectorItemTreeView,
    SelectorItemEditor,
  },
  data: () => ({
    selectedKey: '',
    selectorItemDef: null,
    selectorItemValues: null,
  }),
  methods: {
    ...mapActions(['saveSelectorItem']),

    selectTreeItem(key) {
      if (this.selectedKey != key) {
        this.selectorItemDef = SelectorItemDefs[key];
        this.selectorItemValues = MasterData[key];

        this.selectedKey = key;
      }
    },
    async save(items) {
      await this.saveSelectorItem({itemId: this.selectedKey, values: items});
    }
  },
}
</script>
<style lang="scss" scoped>
</style>
