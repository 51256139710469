<template>
<div class="selector-item-tree-view">
  <v-treeview 
    :items="items"
    activatable
    open-on-click
    @update:active="act($event)"
  >
    <template v-slot:prepend="{ item, open }">
      <v-icon v-if="item.children && item.children.length > 0">
        {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
      </v-icon>
      <v-icon v-else>mdi-form-select</v-icon>
    </template>
  </v-treeview>
</div>
</template>
<script>
import { SelectorItemTreeDefs } from '../../../../utils/MasterData';

export default {
  data: () => ({
    items: SelectorItemTreeDefs,
  }),
  methods: {
    act(key) {
      if(key && key.length > 0)
      this.$emit("select", key[0]);
    }
  },
}
</script>
<style lang="scss" scoped>
.selector-item-tree-view {
  background-color: white;
  min-width: 350px;
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  @include scrollAreaY();
}
</style>
